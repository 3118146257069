.editor {
  padding: 10px 0 0 0;
  max-height: 100vh;
  overflow: scroll;
  position: relative;

  textarea {
    border: none;

    &:focus {
      border: none;
    }
  }

  .mtk6,
  .mtk8 {
    font-weight: bold;
  }

  .add-snippet-icon {
    left: 2px;
    position: absolute;
    height: 18px;
    width: 18px;
    opacity: 0.5;
  }

  .snippet-options-item.complete-guide {
    width: fit-content;
    font-size: small;
    font-weight: bold;
    text-decoration: underline;
  }

  .snippet-options {
    display: flex;
    flex-wrap: wrap;
    font-size: small;
    font-weight: bold;
    text-decoration: underline;

    &-item {
      padding: 2px 4px;
      margin: 2px 0;
      flex-grow: 1;
      text-align: center;
    }
    &-item.selected {
      background-color: var(--linkColor);
      border-radius: 4px;
    }
  }

  .snippet-blocks {
    box-shadow: 0 0 1px 1px var(--shadowColor);
    padding: 5px 5px 2px;
    margin: 5px 0;
    align-items: flex-start;

    > * {
      width: 100%;
    }

    button {
      float: right;
    }

    &-item {
      overflow: scroll;
      & > * {
        margin-bottom: 10px;
      }

      .markdown-text {
        .block-code-snippet {
          font-size: 85%;
          text-shadow: none;
          font-family: Menlo, Monaco, Consolas, "Andale Mono", "Ubuntu Mono",
            "Courier New", monospace;
          direction: ltr;
          text-align: left;
          white-space: pre;
          word-spacing: normal;
          word-break: normal;
          line-height: 1.4;
          tab-size: 4;
          hyphens: none;
          padding: 10px;
          margin: 0.5em 0px;
          overflow: auto;
          border-radius: 5px;
        }
      }

      .markdown-text .preview-container {
        background: inherit;
      }
      .markdown-rendered .preview-container .preview {
        padding-top: 5px;
        padding-bottom: 5px;
        min-height: 20px;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .snippet-options {
      &-item {
        padding: 5px 2px;
        flex-grow: 1;
        text-align: center;
      }
    }

    .snippet-blocks {
      padding: 0px;

      .preview {
        padding: 0 10px;
      }
    }
  }
}
