.header {
  padding: 10px 15px;
  box-shadow: 0px 1px 3px 0px var(--shadowColor);
  margin-bottom: 2px;
  background: var(--background);

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .app-name {
    flex-grow: 1;
    margin: 0;
  }

  .menu-icon {
    margin-left: 10px;
  }

  .header-options {
    display: flex;
    align-items: center;

    a {
      padding: 0 4px;
      margin-right: 5px;
      color: var(--fontColor);

      &.cheatsheet-link {
        text-decoration: underline;
      }

      svg {
        margin-top: 3px;
      }
    }

    .header-user-name {
      flex-grow: 1;
      font-weight: 500;
      > a {
        color: var(--fontColor);
      }
    }
  }

  .header-menu {
    position: fixed;
    right: 5px;
    top: 46px;
    background: white;
    flex-direction: column;
    box-shadow: 0 0 1px 0px var(--shadowColor);
    z-index: 100;

    a,
    p.link {
      display: block;
      padding: 8px 15px 12px 10px;
    }
  }

  @media screen and (max-width: 390px) {
    .header-options {
      width: 100%;
      margin-top: 4px;
    }
    .header-menu {
      top: 70px;
    }
  }

  .theme-type-selector {
    height: 20px;
    width: 38px;
    background-color: #888;
    border-radius: 10px;
    position: relative;
    margin-right: 10px;

    &-lever {
      height: 16px;
      width: 16px;
      background-color: white;
      border-radius: 10px;
      border: 2px solid #888;
      position: absolute;
      transition: all 200ms linear;

      &.theme-lever-light {
        left: 0;
      }

      &.theme-lever-dark {
        left: 19px;
      }
    }

    .theme-type-selector-label {
      height: 18px;
      padding: 0 2px;

      &.theme-label-light {
        float: right;
      }

      &.theme-label-dark {
        float: left;
      }
    }
  }
}
