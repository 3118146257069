.page-user-home {
  .page-content-user-home {
    > a {
      color: inherit;
    }
    .card-doc {
      background-color: var(--previewBackground);
      margin: 20px auto;
      padding: 0;
      width: 600px;
      max-height: 60vh;
      overflow: hidden;

      .card-content-doc {
        padding: 10px;

        .doc-card-options {
          display: flex;
          flex-wrap: wrap;
          font-size: small;

          a {
            display: inline-flex;
            align-items: flex-end;
            flex-grow: 1;

            svg {
              height: 14px;
              width: 14px;
              margin-left: 1px;
              margin-bottom: 1px;
            }
          }

          .created-date {
            color: var(--fontColorLighter);
          }
        }
      }

      @media screen and (max-width: 700px) {
        width: 95%;
      }
    }
  }
}
