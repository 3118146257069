html {
  scroll-behavior: smooth;
}

.App {
  height: 100vh;
  color: var(--fontColor);
  background: var(--background);

  input,
  textarea {
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 5px;
    outline: none;
    margin: 4px 0;
    font-size: inherit;

    &:focus {
      border: 2px solid var(--shadowColor);
    }
  }

  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  button {
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    background-color: var(--linkColor);
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;

    svg {
      fill: white;
      height: 16px;
      width: 16px;
      margin: 0 4px 0 -5px;
    }

    &.slim {
      padding: 3px 4px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:hover {
      background-color: slategray;
    }

    &.secondary {
      background: white;
      color: var(--linkColor);
      svg {
        fill: var(--linkColor);
        // height: 16px;
        // width: 16px;
        margin: 0 0 0 0;
      }
    }

    &.secondary:hover {
      background-color: lightgrey;
    }

    &.plain {
      background: transparent;
      color: var(--linkColor);
      padding: 4px;
      font-size: 16px;
      svg {
        fill: var(--linkColor);
        // height: 16px;
        // width: 16px;
        margin: 0 4px 0 0;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  a,
  p.link {
    text-decoration: none;
    color: var(--linkColor);
    svg {
      fill: var(--linkColor);
    }
    margin: 0;
  }

  svg {
    fill: var(--fontColorLighter);
  }

  .theme-popup {
    position: absolute;
    right: 80px;
    top: 54px;
  }

  .auth-modal-content {
    text-align: center;
    input {
      width: calc(90% - 14px);
    }
    .auth-action {
      margin-top: 10px;
      width: 90%;
    }

    #google-signin {
      margin: 10px;
      iframe {
        margin: 0 auto !important;
      }
    }
  }
}
