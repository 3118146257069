.preview-container {
  padding: 0;
  background-color: var(--previewBackground);
  .preview {
    padding: 0 15px;
    max-width: 66ch;
    line-height: 1.5;
    overflow: auto;
    img {
      max-width: 100%;
      vertical-align: middle;
    }
    a {
      color: cadetblue;
    }

    a.generated-heading-autolink {
      color: inherit;

      &:hover:after {
        width: 16px;
        height: 16px;
        content: " ";
        margin-left: 5px;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='gray' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
    blockquote {
      color: var(--fontColorLight);
      padding: 0.5em 1.5em;
      border-left: 5px solid rgba(0, 0, 0, 0.23);
      margin: 1.2em 0;

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    table {
      border-collapse: collapse;
      border: 1px solid var(--dividerColor);
      th,
      td {
        padding: 6px 13px;
        border-collapse: collapse;
        border: 1px solid var(--dividerColor);
      }
    }

    div.block-code-parent {
      position: relative;
      .code-copy {
        position: absolute;
        opacity: 0.9;
        background: #8884;
        top: 0;
        right: 0;
        margin: 6px;
        border-radius: 4px;
        height: 18px;
        width: 18px;
        padding: 4px;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
