.cheat-sheet-content {
  .snippet-options-item.complete-guide {
    width: fit-content;
    font-size: small;
    font-weight: bold;
    text-decoration: underline;
  }

  .snippet-options {
    display: flex;
    flex-wrap: wrap;
    font-size: small;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 20px;

    &-item {
      padding: 2px 4px;
      margin: 2px 0;
      flex-grow: 1;
      text-align: center;
    }
    &-item.selected {
      background-color: var(--linkColor);
      border-radius: 4px;
    }
  }

  .snippet-blocks {
    box-shadow: 0 0 1px 1px var(--shadowColor);
    padding: 5px 5px 2px;
    margin: 5px auto;
    align-items: flex-start;
    max-width: 700px;

    > * {
      width: 100%;
    }

    button {
      float: right;
    }

    &-item {
      overflow: scroll;
      & > * {
        margin-bottom: 10px;
      }

      .markdown-text {
        .block-code-snippet {
          font-size: 85%;
          text-shadow: none;
          font-family: Menlo, Monaco, Consolas, "Andale Mono", "Ubuntu Mono",
            "Courier New", monospace;
          direction: ltr;
          text-align: left;
          white-space: pre;
          word-spacing: normal;
          word-break: normal;
          line-height: 1.4;
          tab-size: 4;
          hyphens: none;
          padding: 10px;
          margin: 0.5em 0px;
          overflow: auto;
          border-radius: 5px;
        }
      }

      .markdown-text .preview-container {
        background: inherit;
      }
      .markdown-rendered .preview-container .preview {
        padding-top: 5px;
        padding-bottom: 5px;
        min-height: 20px;
      }
    }
  }
}
