.notification-popup {
  padding: 15px 40px 15px 15px;
  background: #444;
  opacity: 0.8;
  max-width: 400px;
  width: fit-content;
  color: white;
  position: relative;
  border-radius: 4px;
  z-index: 100;
  &.notification-popup-error {
    background: firebrick;
  }

  &.notification-popup-success {
    background: green;
  }

  &-close {
    position: absolute;
    right: -8px;
    top: -8px;
    border-radius: 24px;
    fill: white !important;
    background: #222;
    padding: 4px;
    height: 16px;
    width: 16px;
  }

  &-title {
    font-weight: bold;
    margin: 0 0 5px;
  }

  &-text {
    margin: 0;
  }
}
