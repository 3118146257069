.editor-page-container {
  display: flex;
  height: 100vh;
  margin-bottom: 20px;

  hr {
    background-color: var(--dividerColor);
    height: 1px;
    border: 0;
    margin: 0;
  }

  .published-slug-link {
    display: flex;
    align-items: center;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  &.preview-enabled {
    .editor-page-sidebar {
      width: 6%;
    }
    .editor-section {
      width: 50%;
      min-width: 0;
      margin: 0 5px;
    }
  }

  .editor-page-sidebar {
    padding-top: 50px;
    width: 20%;
    .all-docs-link {
      padding-left: 20px;
    }
  }

  .editor-section {
    width: 60%;
    min-width: 600px;
    min-height: 100%;
    border-radius: 0px;
    overflow: hidden;
    margin: 10px;

    .editor-options {
      padding: 5px 10px 10px;
      margin: 2px 2px 0 2px;
      box-shadow: 0px 0px 1px 0 #444;
      background-color: var(--editorOptionBackground);

      input {
        background-color: inherit;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #888;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #888;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: grey;
        }

        &:focus {
          background-color: var(--background);
        }
      }

      .editor-options-row {
        padding: 2px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: var(--fontColorLight);

        &.slug-option {
          font-size: small;
          padding-left: 5px;
          padding-right: 5px;
        }

        .slug-link-prefix {
          font-weight: 200;
        }
        button {
          margin: 5px;
          height: min-content;
          &.plain {
            padding: 0 2px;
          }
        }

        input {
          border: none;
        }
        #doc-name {
          font-size: 22px;
          padding: 4px;
          flex-grow: 1;
          width: 180px;
          color: var(--fontColor);
        }
        #doc-slug {
          flex-grow: 1;
          font-size: inherit;
          margin: 0;
          color: var(--fontColor);
          padding: 0;
        }

        .preview-icon {
          min-height: 24px;
          min-width: 24px;
          margin: 0 5px 0 2px;
          padding: 0 5px;
        }

        .zen-icon {
          min-height: 28px;
          min-width: 28px;
          margin-bottom: 4px;
        }

        .snippet-icon {
          margin: 0 5px 0 4px;
          min-height: 24px;
          min-width: 24px;
        }

        &-label {
          font-weight: bold;
          color: var(--fontColorLighter);
        }
      }
    }

    .editor {
      padding: 20px 0;
      height: 100%;
      overflow: scroll;
      margin: 0 2px 2px;
      box-shadow: 0px 0px 1px 0 var(--shadowColor);
      background-color: var(--editorPaddingColor);
    }
  }

  .preview-section {
    height: 100%;
    background-color: white;
    width: 45%;
    box-shadow: 0px 0px 2px 0px var(--shadowColor);
    animation: fadeIn 3s;
    animation-delay: 5s;
    animation-fill-mode: forwards;
    overflow-y: hidden;

    .preview-options {
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: var(--backgroundGrey);

      .doc-name {
        flex-grow: 1;

        span {
          color: var(--fontColor);
          padding-right: 4px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .preview-container {
      width: 100%;
      max-width: 100%;
      height: 100%;
      overflow-y: scroll;

      .preview {
        padding-bottom: 200px;
        max-width: 100%;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 400px) {
    .editor {
      zoom: 0.83;
    }
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;

    .editor-section {
      width: 100%;
      height: 100vh;
      overflow: scroll;
      border-radius: 0;
      min-width: 0;
      margin: 0;
      min-height: 0;

      .editor-options,
      .editor {
        margin: 0;
      }

      .editor {
        padding: 0;
      }

      .editor-options {
        padding: 0 5px 4px;

        .editor-options-row {
          #doc-name {
            width: 120px;
            margin: 0;
            font-size: 18px;
          }

          button {
            font-size: 12px;
            padding: 6px 4px;
            margin: 2px;
          }

          svg {
            height: 13px;
            width: 13px;
            margin: 0;
          }

          .preview-icon,
          .zen-icon,
          .snippet-icon {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }

    &.preview-enabled .editor-section {
      height: 55vh;
      width: 97%;
    }

    .preview-section {
      width: 100%;
      height: 40vh;
      overflow: scroll;
      min-width: 0;
    }

    .editor-page-sidebar {
      display: none;
    }
  }
}

.zen-enabled {
  &.page-editor {
    background-color: var(--editorPaddingColor);
  }
  .zen-icon {
    fill: mediumturquoise;
  }

  .editor-page-container {
    height: 100vh;
    margin-bottom: 0;
  }

  .editor-section {
    margin: 0;
    .editor-options {
      padding: 0 4px;
      .editor-options-row {
        padding: 0;
        input,
        #doc-name {
          margin: 0;
          padding: 0;
        }
        &.slug-option {
          display: none;
        }
        button {
          display: none;
        }
      }
    }
  }

  .preview-section {
    .preview-options {
      padding: 5px;
      p {
        margin: 0;
      }
    }
  }
}
