.page-preview {
  background: var(--previewBackground);

  &.folder .page-content {
    .doc-info > * {
      display: none;
    }
  }

  .folder-header {
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: var(--backgroundGrey);
    width: 280px;
    .list-icon {
      padding: 2px 4px;
      box-shadow: 0px 0px 3px 1px var(--shadowColor);
      border-radius: 5px;
      margin-right: 10px;
    }

    &-username-link {
      color: var(--fontColor);
      margin-right: 8px;
      font-weight: bold;
    }

    &-folder-link {
      margin-left: 8px;
      background-color: var(--folderLabelColor);
      padding: 3px 8px;
      color: white;
      border-radius: 4px;
      font-weight: bold;
    }
  }

  .published-content {
    display: flex;
    min-height: 100vh;

    .folder-outline-container {
      margin-top: -2px;
      width: 300px;
      min-width: 300px;
      max-width: 300px;
      overflow: scroll;

      .folder-outline {
        padding: 12px 8px 0px;
        a {
          font-size: 14px;
          font-weight: 300;
          color: var(--fontColorLight);
        }

        &.active > a {
          color: var(--linkColor);
        }
      }

      > .folder-outline {
        height: 100%;
        background-color: var(--backgroundGrey);
        padding-left: 10px;
        width: 300px;
        padding-top: 20px;
        > a {
          font-size: 18px;
        }

        > .folder-outline {
          > a {
            font-size: 16px;
          }
        }
      }
    }

    .document-outline-container {
      display: none;
      width: 300px;
      .document-outline {
        position: fixed;
        top: 160px;
        width: 300px;
        a {
          display: block;
          font-size: 14px;
          padding: 0px 0px 10px 11px;
          font-weight: 700;
          color: var(--fontColorLight);

          &.document-outline-item-active {
            color: var(--linkColor);
          }
        }

        &-title {
          font-weight: 300;
          font-size: 18px;
          padding-left: 10px;
          padding-bottom: 4px;
          margin-bottom: 10px;
          border-bottom: 1px solid var(--fontColorLighter);
        }
      }
    }

    .preview-container {
      width: max-content;
      padding-top: 0.67em;
      flex-grow: 1;
      padding-bottom: 200px;
    }

    &.show-folder-header {
      .folder-header {
        width: 100%;
        background-color: var(--previewBackground);
        box-shadow: 0px 1px 2px 0 var(--shadowColor);
        position: fixed;
        padding: 14px;
        top: 0;
      }

      .folder-outline-container {
        position: fixed;

        > .folder-outline {
          height: 100vh;
        }
      }

      .preview-container {
        margin-left: 300px;
      }
    }
  }

  @media screen and (min-width: 760px) {
    .published-content {
      .preview {
        margin: 0 auto;
      }

      .document-outline-container {
        display: block;
      }

      .list-icon {
        display: none;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .published-content {
      display: flex;
      .folder-outline-container {
        overflow: unset;
        &.hide {
          width: 0;
          max-width: 0;
          min-width: 0;
          .folder-outline {
            display: none;
          }
        }

        > .folder-outline {
          margin-top: 50px;
        }
      }

      .folder-header {
        width: 100%;
        background-color: var(--previewBackground);
        box-shadow: 0px 1px 2px 0 var(--shadowColor);
        padding: 14px;
        position: absolute;
        z-index: 100;
      }

      &.show-folder-header {
        .preview-container {
          margin-left: 0;
        }
        .folder-outline-container {
          position: relative;

          > .folder-outline {
            position: fixed;
            top: 0;
          }
        }
      }
      .preview-container {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &.folder {
      .published-content {
        .preview-container {
          padding-top: 80px;
        }
      }
    }

    &.no-folder {
      .published-content {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .doc-header {
    display: flex;
    align-items: flex-start;

    .edit-icon {
      fill: var(--linkColor);
      background-color: var(--background);
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0px 0px 3px 2px var(--shadowColor);
      margin: 4px;
    }
    .doc-info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-grow: 1;

      a {
        display: inline-flex;
        align-items: flex-end;
        flex-grow: 1;
      }

      .created-date {
        color: var(--fontColorLighter);
      }
    }
  }
}
