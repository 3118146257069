.modal-container {
  position: fixed;
  background: #00000088;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;

  .modal {
    margin: 10% auto auto auto;
    width: 40%;
    background: var(--background);
    border-radius: 8px;
    box-shadow: 1px 1px 3px 2px #666;

    &-header {
      padding: 20px 20px 10px;
    }

    &-footer {
      padding: 20px;
    }

    &-footer {
      text-align: right;
    }

    &-content {
      max-height: 60vh;
      padding: 10px 20px;

      overflow: auto;
      background: /* Shadow covers */ linear-gradient(
          var(--background) 30%,
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(rgba(255, 255, 255, 0), var(--background) 70%) 0 100%,
        /* Shadows */ linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)) 0 100%;
      background-repeat: no-repeat;
      background-color: var(--background);
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      /* Opera doesn't support this in the shorthand */
      background-attachment: local, local, scroll, scroll;

      p {
        margin: 0.5em 0;
      }
    }

    &-header {
      margin: 0;

      &-container {
        display: flex;

        .modal-header {
          flex-grow: 1;
          font-size: 20px;

          &-empty {
            height: 0;
          }
        }

        svg {
          height: 16px;
          width: 16px;
          min-height: 16px;
          min-width: 16px;
          margin: 10px 10px 2px 10px;
        }
      }
    }
  }

  @media screen and (max-width: 760px) {
    .modal {
      width: 80%;

      &-header {
        padding: 20px 10px 10px;
      }

      &-footer {
        padding: 20px 10px;
      }
      &-content {
        padding: 10px 10px;
        max-height: 80vh;
      }
    }
  }
}

.modal-container.empty-footer {
  .modal .modal-footer {
    padding: 0 20px 20px;
  }
}
