.path-route {
  margin-left: 20px;
  margin-bottom: 20px;

  &-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 24px;
  }

  .folder-published-link svg {
    height: 18px;
    margin-bottom: -3px;
  }
}
