.page {
  min-height: 100%;
  background-color: var(--pageBackground);
  overflow: auto;

  .page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    .page-title {
      margin: 0;
      flex-grow: 1;
    }

    &-actions {
      display: flex;
      align-items: flex-end;
      > * {
        margin: 0 5px;
      }
      svg {
        height: 46px;
        width: 46px;
      }
    }
  }

  .page-content {
    padding: 0 20px;
  }

  .notification-popup {
    position: absolute;
    right: 60px;
    top: 60px;
  }

  @media screen and (max-width: 700px) {
    .page-content,
    .page-header {
      padding: 10px;
    }

    .notification-popup {
      position: absolute;
      margin: 0 10px;
      left: 0;
      top: 50vh;
    }
  }

  &.no-padding {
    .page-content,
    .page-header {
      padding: 0;
    }
  }

  .page-message {
    padding: 0 60px;
    margin-top: 2px;

    color: #675;

    .in-page-message p {
      margin: 0 0 20px;
      font-size: large;
    }
  }
}
