.all-documents {
  .page-header-nested {
    padding-bottom: 10px;
  }

  .page-content {
    height: 100vh;
    padding-left: 0;
  }

  .documents-list-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .folder-info {
      padding: 0 10px 20px 0;
      margin-right: 10px;
      background-color: var(--editorOptionBackground);
      height: 100%;
      padding-left: 20px;

      p {
        margin-bottom: 4px;
      }
      button {
        margin-top: 10px;
        width: calc(100% - 20px);
      }
      textarea {
        width: calc(100% - 40px);
        min-height: 100px;
        border: none;
      }
      input {
        width: calc(100% - 40px);
        border: none;
      }
    }
  }
  .documents-list {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    padding-left: 10px;
    .document-card {
      width: 230px;
      margin: 10px;

      &-header {
        display: flex;

        &-title {
          color: var(--fontColor);
        }
        svg {
          height: 18px;
          width: 18px;
          margin-left: 4px;
          margin-top: 2px;
          fill: var(--fontColorLighter);
        }
      }

      &-slug {
        font-size: small;
        display: flex;
        align-items: center;
        font-weight: bold;
        text-decoration: underline;

        a {
          color: var(--fontColorLighter);
          display: flex;
          align-items: center;
        }

        svg {
          height: 18px;
          margin-left: 4px;
          fill: var(--fontColorLighter);
        }
      }

      &-preview {
        white-space: pre;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        height: 175px;
        padding: 8px;
        border-radius: 2px;
        overflow: scroll;
        zoom: 0.7;
        color: var(--fontColor);

        &.folder-icon {
          border: none;
          width: 100%;
          fill: peru;
        }
      }

      &-timestamp {
        display: flex;
        flex-wrap: wrap;
        font-size: small;
        justify-content: flex-end;
        padding: 2px;

        &-label {
          margin-right: 5px;
        }
      }
    }
  }

  svg.folder {
    fill: peru;
  }

  .modal {
    .modal-content {
      textarea {
        width: calc(100% - 20px);
        min-height: 50px;
      }
      input {
        width: calc(100% - 20px);
      }
      button {
        float: right;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .page-content {
      padding-right: 0;
    }
    .documents-list-container {
      .folder-info {
        border-right: none;
        border-bottom: 1px solid var(--dividerColor);
        height: fit-content;
        margin-right: 0;
        width: 100%;
      }
      .documents-list {
        justify-content: center;
        width: 100%;
      }
    }
    .path-route {
      margin-left: 10px;
    }
  }
}
