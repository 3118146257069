.card {
  border-radius: 4px;
  margin: 5px;
  box-shadow: 2px 2px 3px 1px var(--shadowColor);
  padding: 10px;
  background: var(--background);

  .card-title {
    margin: 0;
  }
}
